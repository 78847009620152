import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/navbar";
import Home from "./components/home";
import About from "./components/about";
import Contact from "./components/contact";

import "bootstrap/dist/css/bootstrap.min.css";

//StateFull component / class component
//Snippet: cc
class App extends React.Component {
  state = {
    isMenuDisplayed: false,
  };

  displayMenu = () => {
    console.log("DisplayingMenu");
    //add/remove Hidden class to mainMenu
    let isMenuShown = !this.state.isMenuDisplayed;
    //=========TODO: keep state between refresh============================
    this.setState({ isMenuDisplayed: isMenuShown });
  };

  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <div className="App">
            <header>
              <NavBar
                onClickMenuButton={() => this.displayMenu()}
                isMenuShown={this.state.isMenuDisplayed}
                onClickLink={this.displayMenu}
              />
            </header>
            <Routes>
              <Route path="/home" exact element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </div>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;
