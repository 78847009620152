import React, { Component } from "react";
import { Link } from "react-router-dom";
import TypeWriter from "typewriter-effect";
import "../css/home.scss";
import "../css/button3D.scss";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="jumbotron jumbotron-fluid centered-content">
          <div className="container text-center text-home">
            <TypeWriter
              onInit={(typewriter) => {
                typewriter
                  .changeDelay(17)
                  .typeString("<strong>Hey! my name is Charles Silari.")
                  .pauseFor(300)
                  .typeString("<br>")
                  .pauseFor(300)
                  .changeDelay(13)
                  .typeString(
                    "I am currently studying for a master's degree in Computer Science at Bordeaux University."
                  )
                  .pauseFor(300)
                  .typeString("<br>")
                  .pauseFor(300)
                  .changeDelay(11)
                  .typeString(
                    "I have no particular preference between front-end development (using JS/React)"
                  )
                  .pauseFor(100)
                  .typeString("<br>")
                  .pauseFor(100)
                  .typeString("and back-end development (Java,Python,etc).")
                  .pauseFor(300)
                  .typeString("<br>")
                  .pauseFor(300)
                  .changeDelay(9)
                  .typeString(
                    "This website was made using JavaScript, and React as its core Library."
                  )
                  .pauseFor(300)
                  .typeString("<br>")
                  .pauseFor(300)
                  .typeString(
                    "The idea behind it is to introduce myself, and show some of my work."
                  )
                  .start();
              }}
            />
          </div>
        </div>
        <div>
          <Link to="/about" onClick={() => this.onClickMenuButton()}>
            <button class="pushable">
              <span class="shadow"></span>
              <span class="edge"></span>
              <span class="front">Visit the next page</span>
            </button>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
