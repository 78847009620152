import React, { Component } from "react";
import logo from "../logo.svg";
import "../css/logo.scss";
import "../css/mainMenu.scss";
import { Link } from "react-router-dom";
//App-logo

//Class Component
//Snippet: cc

class NavBar extends Component {
  classes = [
    { id: 1, classes: "mobile-nav-button__line" },
    { id: 2, classes: "mobile-nav-button__line" },
    { id: 3, classes: "mobile-nav-button__line" },
  ];

  getMenuClasses = () => {
    let classes = "header";
    if (!this.props.isMenuShown) {
      classes += " hidden";
    }
    return classes;
  };

  onClickMenuButton = () => {
    //update button style
    const newClasses = [...this.classes];

    for (let index = 0; index < newClasses.length; ++index) {
      const element = newClasses[index];
      const classToSearch = " mobile-nav-button__line--" + (index + 1);
      const classIndex = element.classes.indexOf(classToSearch);
      //console.log(classIndex);
      if (classIndex !== -1) {
        //console.log("test");
        element.classes = element.classes.substr(0, classIndex);
      } else {
        element.classes += classToSearch;
      }
    }
    this.setState(newClasses);
    //display menu
    this.props.onClickMenuButton();
  };

  render() {
    return (
      <React.Fragment>
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img
                src={logo}
                alt="logo"
                width="44"
                height="32"
                className="d-inline-block align-text-top react-logo"
              />
              csilari.me
            </a>
            <div
              class="mobile-nav-button"
              onClick={() => this.onClickMenuButton()}
            >
              <div class={this.classes[0].classes}></div>
              <div class={this.classes[1].classes}></div>
              <div class={this.classes[2].classes}></div>
            </div>
            <div className=" title">{this.props.currentTitle}</div>
          </div>
        </nav>
        <div className={this.getMenuClasses()}>
          <div className="box-1">
            <Link to="/home" onClick={() => this.onClickMenuButton()}>
              <div className="btn-mm btn-mm-one">
                <span>HOME</span>
              </div>
            </Link>
          </div>
          <div className="box-2">
            <Link to="/about" onClick={() => this.onClickMenuButton()}>
              <div className="btn-mm btn-mm-two">
                <span>ABOUT</span>
              </div>
            </Link>
          </div>

          <div className="box-3">
            <Link to="/contact" onClick={() => this.onClickMenuButton()}>
              <div className="btn-mm btn-mm-three">
                <span>CONTACT</span>
              </div>
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

/* <img
                  src={
                    this.buttons.find((button) => button.name === "hamburger")
                      .url
                  }
                  width="36"
                  height="29"
                />*/

export default NavBar;
