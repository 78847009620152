import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import Popup from "./popup";
import "../css/contact.scss";

function Contact() {
  const form = useRef();
  const keyName = "from_name";
  const keyEmail = "reply_to";
  const keymessage = "message";

  const [bgClasses, setBgClasses] = useState("popup ");
  const [popupClasses, setPopupClasses] = useState("popup-inner ");
  const [popupMessages, setPopupMessages] = useState({
    header: "",
    message: "",
  });

  const changePopupState = () => {
    console.log("Chaning popup state");
    if (bgClasses.includes("active")) {
      setBgClasses(bgClasses.replace("active", ""));
      setPopupClasses(popupClasses.replace("displayPopup", ""));
    } else {
      console.log(bgClasses + "active");
      console.log(popupClasses + "displayPopup");

      setBgClasses(bgClasses + "active");
      setPopupClasses(popupClasses + "displayPopup");
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_92zpbkb",
        "template_afpvb2o",
        form.current,
        "user_Q4nqq6Gs7YtqZalwn74zo"
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setPopupMessages({
          header: "Message Successfully sent!",
          message: "I will get back to you ASAP",
        });

        changePopupState();
      })
      .catch((err) => {
        console.log("FAILED...", err);
        setPopupMessages("Failed to send the message.");
        setPopupMessages({
          header: "Failed to send the message.",
          message: "Try again later, sorry for the inconveniance",
        });
        changePopupState();
      });
    //Dev
    //setPopupMessages("Message Successfully sent!");
    //changePopupState();
  };

  return (
    <React.Fragment>
      <section id="contact">
        <div className="contact-wrapper">
          <form
            id="contact-form"
            className="form-horizontal"
            ref={form}
            onSubmit={sendEmail}
          >
            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="NAME"
                  name={keyName}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="EMAIL"
                  name={keyEmail}
                  required
                />
              </div>
            </div>

            <textarea
              className="form-control"
              rows="10"
              placeholder="MESSAGE"
              name={keymessage}
              required
            ></textarea>

            <button
              className="btn btn-secondary send-button"
              id="submit"
              type="submit"
              value="SEND"
            >
              <div className="alt-send-button">
                <i className="fa fa-paper-plane"></i>
                <span className="send-text">SEND</span>
              </div>
            </button>
          </form>
          <div className="direct-contact-container">
            <h1 className="section-header">Contact Me</h1>

            <ul className="contact-list">
              <li className="list-item">
                <i className="fa fa-map-marker fa-2x">
                  <span className="contact-text place">France, Talence</span>
                </i>
              </li>

              <li className="list-item">
                <i className="fa fa-phone fa-2x">
                  <span className="contact-text phone">
                    <a href="tel:+336 82 59 92 90" title="Give me a call">
                      +33 6 82 59 92 90
                    </a>
                  </span>
                </i>
              </li>

              <li className="list-item">
                <i className="fa fa-envelope fa-2x">
                  <span className="contact-text gmail">
                    <a
                      href="mailto:charlesilari99@gmail.com"
                      title="Send me an email"
                    >
                      charlesilari99@gmail.com
                    </a>
                  </span>
                </i>
              </li>
            </ul>

            <ul className="social-media-list">
              <li>
                <a
                  rel="noopener noreferrer"
                  href="https://github.com/csilari"
                  target="_blank"
                  className="contact-icon"
                >
                  <i className="fa fa-github" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/charles.silari/"
                  className="contact-icon"
                >
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://fr.linkedin.com/in/charles-silari-a94a7a1a2"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-icon"
                >
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://discord.com/users/405842473231843328"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-icon"
                >
                  <i className="fab fa-discord" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Popup
        onChangeState={changePopupState}
        bgClasses={bgClasses}
        popupClasses={popupClasses}
      >
        <h1>{popupMessages.header}</h1>
        <h2>{popupMessages.message}</h2>
      </Popup>
    </React.Fragment>
  );
}

/*class Contact extends Component {
  onSubmit = () => {};
  redirect = () => {
    console.log("Redirecting");
    window.location.href = "https://www.facebook.com/charles.silari/";
    return null;
  };

  render() {
    return (
      <React.Fragment>
        <section id="contact">
          <div className="contact-wrapper">
            <form
              id="contact-form"
              className="form-horizontal"
              onSubmit={onSubmit}
            >
              <div className="form-group">
                <div className="col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="NAME"
                    name="name"
                    value={toSend.from_name}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="col-sm-12">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="EMAIL"
                    name="email"
                    value={toSend.reply_to}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <textarea
                className="form-control"
                rows="10"
                placeholder="MESSAGE"
                name="message"
                value={toSend.message}
                onChange={handleChange}
                required
              ></textarea>

              <button
                className="btn btn-primary send-button"
                id="submit"
                type="submit"
                value="SEND"
              >
                <div className="alt-send-button">
                  <i className="fa fa-paper-plane"></i>
                  <span className="send-text">SEND</span>
                </div>
              </button>
            </form>

            <div className="direct-contact-container">
              <h1 className="section-header">Contact Me</h1>

              <ul className="contact-list">
                <li className="list-item">
                  <i className="fa fa-map-marker fa-2x">
                    <span className="contact-text place">France, Talence</span>
                  </i>
                </li>

                <li className="list-item">
                  <i className="fa fa-phone fa-2x">
                    <span className="contact-text phone">
                      <a href="tel:+336 82 59 92 90" title="Give me a call">
                        +33 6 82 59 92 90
                      </a>
                    </span>
                  </i>
                </li>

                <li className="list-item">
                  <i className="fa fa-envelope fa-2x">
                    <span className="contact-text gmail">
                      <a
                        href="mailto:charlesilari99@gmail.com"
                        title="Send me an email"
                      >
                        charlesilari99@gmail.com
                      </a>
                    </span>
                  </i>
                </li>
              </ul>

              <ul className="social-media-list">
                <li>
                  <a
                    rel="noopener noreferrer"
                    href="https://github.com/csilari"
                    target="_blank"
                    className="contact-icon"
                  >
                    <i className="fa fa-github" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/charles.silari/"
                    className="contact-icon"
                    onClick={this.redirect}
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://fr.linkedin.com/in/charles-silari-a94a7a1a2"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                  >
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://discord.com/users/405842473231843328"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                  >
                    <i className="fab fa-discord" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>

              <div className="copyright">&copy; ALL OF THE RIGHTS RESERVED</div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}*/

export default Contact;
