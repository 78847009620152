import "../css/popup.scss";

function Popup(props) {
  return (
    <div className={props.bgClasses}>
      <div className={props.popupClasses}>
        {props.children}
        <button
          className="close-btn btn btn-dark"
          onClick={props.onChangeState}
        >
          close
        </button>
      </div>
    </div>
  );
}

export default Popup;
